<template>
  <v-container
    fluid
    class="light-blue lighten-5"
  >
    <!--------------------------------------STEPPER-------------------------->
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="pb-0 mb-0"
      >
        <v-card class="white px-auto pb-0 mb-0">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-home-circle"
              >
                HOME INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-home-circle"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="3"
                complete
                complete-icon="mdi-home-circle"
              >
                REVIEW COVERAGE
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                step="4"
              >
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="5">
                DOWNLOAD YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <!------------------------------------------------FORM---------------------------------->
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="mt-0 pt-1"
      >
        <v-card
          class="mt-0 pb-16 mb-16"
        >
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <v-form
              ref="form"
              class="px-8 font-weight-bold"
              @submit.prevent="submit"
            >
              <v-row>
                <v-col>
                  <div class="title-text text-center mt-2">
                    $1,523.00 /year
                  </div>
                </v-col>
              </v-row>
              <!---------------------PRIMARY TRAVELER----------------------->
              <v-row>
                <v-col>
                  <div class="sub-title text-center mt-n6">
                    Payment Plans Available
                  </div>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <v-divider class="black"></v-divider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="6"
                  class="d-flex justify-start"
                >
                  <ul style="list-style: none;">
                    <li>Deductible</li>
                  </ul>
                </v-col>
                <v-col
                  cols="4"
                  class="d-flex justify-start"
                >
                  <ul style="list-style: none;">
                    <li>$2500</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <v-divider class="black"></v-divider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="6"
                  class="d-flex justify-start"
                >
                  <ul style="list-style: none;">
                    <li>Dwelling</li>
                    <li>Other Structures</li>
                    <li>Loss of Use</li>
                    <li>Actual Cash Value or Replacement</li>
                    <li>Dwelling - Extended Replacement</li>
                  </ul>
                </v-col>
                <v-col
                  cols="4"
                  class="d-flex justify-start"
                >
                  <ul style="list-style: none;">
                    <li> $1,138,000</li>
                    <li> $113,800</li>
                    <li> $455,200</li>
                    <li> Replacement</li>
                    <li> 50% of Dwelling Limit</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <v-divider class="black"></v-divider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="6"
                  class="d-flex justify-start"
                >
                  <ul style="list-style: none;">
                    <li>Personal Property-Replacement Cost</li>
                  </ul>
                </v-col>
                <v-col
                  cols="4"
                  class="d-flex justify-start"
                >
                  <ul style="list-style: none;">
                    <li>625900-Replacement Cost</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <v-divider class="black"></v-divider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="6"
                  class="d-flex justify-start"
                >
                  <ul style="list-style: none;">
                    <li>Liability</li>
                    <li>Medical Payments</li>
                  </ul>
                </v-col>
                <v-col
                  cols="4"
                  class="d-flex justify-start"
                >
                  <ul style="list-style: none;">
                    <li>$300,000</li>
                    <li>$2000</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <v-divider class="black"></v-divider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="6"
                  class="d-flex justify-start"
                >
                  <ul style="list-style: none;">
                    <li>Inflation Guard</li>
                    <li>Building Ordinance & Law</li>
                    <li>Common Custruction</li>
                    <li>Animal Liability</li>
                  </ul>
                </v-col>
                <v-col
                  cols="4"
                  class="d-flex justify-start"
                >
                  <ul style="list-style: none;">
                    <li>COVERAGE</li>
                    <li>10% of Dwelling Limit</li>
                    <li>COVERAGE</li>
                    <li>COVERAGE</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <v-divider class="black"></v-divider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="6"
                  class="d-flex justify-start"
                >
                  <ul style="list-style: none;">
                    <li style="text-decoration: underline;">
                      Your Discounts
                    </li>
                    <li>Home Alarms and Devices</li>
                    <li>Gated Community</li>
                    <li>Personal Status</li>
                  </ul>
                </v-col>
                <v-col
                  cols="4"
                  class="d-flex justify-start"
                >
                  <ul style="list-style: none;">
                    <li style="text-decoration: underline;">
                      Discounted Amount
                    </li>
                    <li>$430.00</li>
                    <li>$121.74</li>
                    <li>$185.04</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="d-flex justify-center text-center">
                    <v-btn
                      large
                      :disabled="invalid"
                      color="primary"
                      class="mt-2 font-weight-bold"
                      to="/homeowners/pay"
                      @click="clickedFieldGlobal('Purchase Policy', 45, 'Homeowners Survey v1')"
                    >
                      Purchase Policy
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
      }
    }
  }

</script>

<style lang="scss" scoped>
.title-text {
  font-size: 36px;
  font-weight: 400;
  color: #00A1B7;
}

.green-background {
  background-color: #D8FDD7;
}
.sub-title{
  font-size: 20px;
  font-weight: 700;
  color: #000;
}

</style>
